import "@/components/Button/button.scss";
import Col from "@/components/Col/Col";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import Section from "@/components/Section/Section";
import Title from "@/components/Title/Title";
import * as React from "react";
import "../../App.scss";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const PrivacyPolicy = () => (
  <main>
    <CookieCompliance />
    <Header alwaysFixed></Header>
    <div className="spacerHeaderFixed"></div>

    <Section className="PrivacyPolicy Textpage" colFlex>
      <Col size="70">
        <Title
          maintitle="Privacy Policy"
          subtitle="Last Update: October 1st, 2023"
        ></Title>

        <h4>Introduction and Scope</h4>

        <p>
          Ecomtrak s.r.o a Qiriq Company (we, us, our), a Software As A Service 
          (SAAS) - business to business (b2b)- tracking pixel that allows 
          marketers to match online sales of their products to the right 
          campaigns and scale ads with certainty. Marketing pixels, aka 
          (“tracking pixels”), are essentially tiny snippets of code that 
          allow you to gather information about visitors on a website—how they 
          browse, what type of ads they click on, etc.
        </p>

        <p>
          The Ecomtrak marketing pixel (software, service) is used to capture a 
          marketing campaign’s performance, track conversions, and report on the 
          attribution/lookback window of the sale by gathering information about 
          visitors on a website from paid media. Information from the Ecomtrak 
          tracking pixels allows companies to know how many visitors their site 
          has and how many people have seen their digital ad and purchased products.
        </p>

        <p>
          The Ecomtrak, tracking pixel provides personal data insights into ad 
          interactions while adhering to all privacy and compliance laws, including 
          GDPR, HIPAA, CCPA, and all the State Data Privacy Laws in the US. As used 
          in this Privacy Policy, “Personal Data” means information that relates to 
          and to an identifiable individual, as defined under applicable data 
          protection laws.
        </p>

        <p>
          This Privacy Policy applies to the Processing of Customer’s Personal Data 
          when you use our Services and any additional interactions and communications 
          between you and Ecomtrak. The software privacy policy describes how we handle 
          user information and privacy practices for protecting our users. We believe 
          you have the right to know what information we collect about you, how the 
          information collected is being used, and how you can access or change any of 
          this information.
        </p>

        <h4>Contacting Us</h4>

        <p>
          If you have any questions or comments about this Privacy Policy,
          please contact us using the following contact information:
        </p>

        <span className="block">Ecomtrack - A Qiriq Company - 6 Landmark Square, Suite 400. Stamford CT 06902</span>

        <span className="block">
          <a className="underline" href="mailto:helpdesk@ecomtrack.io">helpdesk@ecomtrack.io</a>
        </span>

        <h3>Protecting Your Privacy</h3>

        <p>
          Ecomtrak is committed to protecting the privacy of our users. We process 
          personal data on behalf of our clients (their, they, business) who use 
          our tracking pixel platform and we are considered a data processor. In 
          those situations, it is the client who determines the purposes and means 
          of the processing of personal data. Whilst there is a written contract in 
          place between us and our client which sets out our data protection 
          obligations, we neither control what personal data our clients collect nor 
          how they use it. We are not responsible for their privacy statements.
        </p>

        <p>
          This Privacy Policy neither applies to such clients` use of personal data, 
          nor to our processing of such personal data for such clients. Please consult 
          the terms and conditions and privacy policy of the relevant client to find 
          out how they use your information and to establish whether and for what 
          purpose they collect it.
        </p>

        <h3>Privacy Policy</h3>

        <p>
          This privacy policy (“Privacy  Policy”) describes the collection of personal 
          information by Ecomtrack s.r.o., (“Company,” “we,” “us”, or “our”) from users 
          (“you”, “your”) of our website at www.ecomtrack.io (our “Site”) along with our 
          related websites, partner website, networks, applications, platforms, and other 
          services provided by us and on which a link to this Privacy Policy is displayed 
          (collectively, our “Service” or “Services”). This Privacy Policy also describes 
          our use and disclosure of such information.
        </p>

        <p>
          For the purposes of EU and United Kingdom data protection laws ("Data Protection 
          Legislation"), Ecomtrack s.r.o. is a data processor (i.e., responsibility to 
          provide you (the data controller) with the means to fulfill data subject’s access 
          to their own data and, in general, to implement responsible data collection 
          practices).
        </p>

        <p>
          Please read this Privacy Policy carefully to understand our practices regarding 
          you and your clients (customers) personal information and how we will use it. By 
          accepting this Privacy Policy and Terms and Conditions, you agree to our collection, 
          storage, use and disclosure of you and your clients personal information from your 
          websites as described in this Privacy Policy.
        </p>


        <h3>Privacy</h3>

        <p>
          <li>
            <span>2.1 Privacy:</span> For information about how we collect, use, share, or 
            otherwise process information about you and your use of our Services and Software, 
            please see the privacy policy. You have the option to manage information 
            preferences.
          </li>
          <li>
            <span>2.2 Data Protection Agreements:</span> In some countries, the law requires 
            that we put a data protection agreement in place with you if we handle Personal 
            Data (as defined in the applicable agreement) for you as part of our Services and 
            Software. These agreements are the EU Data Processing Agreement or Data Protection 
            Terms, found in the following locations:
          </li>
          <li>
            <span>(A) European Union (“EU”) Data Processing Agreement (or “DPA”).</span> The 
            DPA terms apply where you provide Personal Data (as defined in the DPA) collected 
            from individuals from countries in the European Economic Area (“EEA”) and the UK 
            and where you are a <span>“Controller”</span> and Ecomtrak  is a <span>“Processor”</span> 
            under the General Data Protection Regulation (EU) 2016/679 (<span>“GDPR”</span>) or 
            any successor for the GDPR associated with the withdrawal of the United Kingdom from 
            the EU.
          </li>
        </p>

        <p>
          Our Data Processing Agreement sets out the additional terms, requirements and 
          conditions on which Ecomtrak will process Personal Data when providing services under 
          the Terms of Service Agreement pursuant to a SAAS License agreement order placed by 
          the Customer. This Agreement contains the mandatory clauses applicable to Personal 
          Data subject to and required by:
        </p>

        <p>
          <li>
            (a)    Article 28(3) of the General Data Protection Regulation ((EU) 2016/679) (EU GDPR) for contracts between 
            controllers and processors;
          </li>
          <li>
            (b)    Article 28(3) of the retained EU law version of the General Data Protection Regulation ((EU) 2016/679) (UK GDPR) 
            for contracts between controllers and processors
          </li>
        </p>

        <p>
          <span>(B) Data Protection Terms.</span> The Data Protection Terms apply where you 
          provide Personal Data (as defined in the Data Protection Terms) collected from 
          individuals outside of the EEA and the UK and where Ecomtrak is Processing (as 
          defined in the Data Protection Terms) this data at your instruction and on your 
          behalf.
        </p>

        <p>
          <span>2.3 Sensitive Personal Information.</span> You agree not to collect, process, 
          or store any Sensitive Personal Information (as defined below) using the Services 
          and Software, except as (A) directly authorized by Ecomtrak, (B) intended by the 
          Services and Software, or (C) governed by the Additional Terms, as applicable. You 
          agree not to transmit, disclose, or make available Sensitive Personal Information to 
          Ecomtrak or Ecomtrak’s third-party providers. “<span>Sensitive Personal Information</span>” 
          means an individual’s financial information, data concerning an individual’s sexual 
          behavior or sexual orientation, medical, or health information protected under any 
          health data protection laws, biometric data, personal information of children 
          protected under any child data protection laws (such as the personal information 
          defined under the U.S. Children’s Online Privacy Protection Act (“<span>COPPA</span>”)) 
          and any additional types of information included within this term or any similar term 
          (such as “sensitive personal data” or “special categories of personal information”) as 
          used in applicable data protection or privacy laws. If you are a Business, you also 
          agree to ensure Business Users’ compliance with this section 2.4 (Sensitive Personal 
          Information).
        </p>

        <p>
          <span>2.4 Transfer of Personal Information.</span> We process and store information 
          in the U.S. and other countries. By using our Services and Software, you authorize 
          Ecomtrak to transfer your personal information across national borders and to other 
          countries where Ecomtrak and its partners operate.
        </p>

        <span>3. Use of Services and Software.</span>
        <p>
          <span>3.1 License.</span> Subject to your compliance with the Terms and applicable 
          law, we hereby grant you a non-exclusive, limited, revocable right for you to install, 
          access and use the Services and Software that we make available to you, and that you 
          license from us. Each license is to be used by only one (1) person and cannot be 
          shared. At the end of your license term, your license(s) will expire as set forth in your 
          order document(s), or the Subscription and Cancellation Terms. The version(s) of the 
          Services and Software available at your renewal date may be different from the version(s) 
          available when you first licensed them from Ecomtrak. The versions of the Services and 
          Software that Ecomtrak supports. You agree that your decision to use, access, or license 
          the Services and Software is not contingent on the delivery of any future functionality 
          or features, or dependent on any oral or written public comments made by us regarding 
          future functionality or features.
        </p>

        <p>
          <span>3.2 Ecomtrak Intellectual Property. </span>We (and our licensors, as applicable) 
          remain the sole owner of all right, title, and interest in the Services and Software. 
          Except as stated in the Terms, we do not grant you any rights to patents, copyrights, 
          trade secrets, trademarks, or any other rights in respect to the items in the Services 
          or Software. This means you may not use our trade names, trademarks, service marks or 
          logos in connection with any product or service that is not ours, or in any manner that 
          is likely to cause confusion. We reserve all rights not granted under the Terms.
        </p>

        <h4>What Information does Ecomtrak collect on the Websites?</h4>
        <p>
          When you visit our websites, and your use of our Services and Software our marketing 
          tracking tags collect data on user behavior and track marketing campaigns. We collect, 
          store and process non-PII information about you and your computer or device log files 
          and sessions.  This information is shared with our b2b clients / customers with data 
          insights and other third-party sites who help us analyze overall activity trends for 
          purchasing products. We may receive reports from third party sites based on the use 
          of these technologies from these companies on an individual or aggregated basis. For 
          more information about cookies and tracking pixels, and how to disable them, please see 
          <a href=" https://support.google.com/chrome/answer/2790761">https://support.google.com/chrome/answer/2790761</a>
        </p>

        <p>
          Here are the different categories of information that may be collected from your 
          device by the Websites, or by partners, affiliates, and other vendors acting on our behalf:
        </p>
        
        <ul className="disc-list">
          <li><p>The type of browser you used to access the Websites</p></li>
          <li><p>The times you accessed the Websites</p></li>
          <li><p>The IP address of the device you used to access the Websites</p></li>
          <li><p>The pages on the Websites that you viewed</p></li>
          <li><p>The pages you visited before navigating to the Website(s).</p></li>
          <li><p>
              Information about the computer or mobile device you use to access the Websites, 
              including hardware model, operating system and version, and mobile network information.
          </p></li>
        </ul>

        <h4>Cookies and similar technologies</h4>

        <p>
          The Service uses cookies and similar technologies to distinguish you from other users 
          of the Service. This enables us to provide you with the service specifically linked 
          to your user profile and is required for the Service to function. Cookies are small 
          files that allow for personalization of the Service experience by saving your 
          information such as user ID and other preferences. The service also uses a related 
          technology called local storage  which  allows  preferences  and  session information 
          to be stored locally on your computer or mobile device.
        </p>

        <p>
          <span>Our Access to Your Content.</span> We will only access, view, or listen to your 
          Content in limited ways, and only as permitted by law. For example, in order to 
          provide the Services and Software, we may need to access, view, or listen to your 
          Content to (A) respond to Feedback or support requests; (B) detect, prevent, or 
          otherwise address fraud, security, legal, or technical issues; and (C) enforce the 
          Terms.
        </p>

        <p>
          Our automated systems may analyze your Content using techniques such as machine learning 
          in order to improve our Services and Software and the user experience. Machine learning 
          describes a subset of artificial intelligence in which a computing system uses algorithms 
          to analyze and learn from data without human intervention to draw inferences from patterns 
          and make predictions. The system may continue to learn and improve over time as it 
          receives more data.
        </p>

        <p>
          We use machine learning to develop and improve our products and services, enabling us to 
          deliver innovative and cutting-edge solutions. We also use machine learning to provide 
          product features and customize our products and services.
        </p>

        <h4>Collection and processing of sensitive information</h4>

        <p>
          The Service does not collect or process ‘sensitive information’ defined as data 
          consisting of racial or ethnic origin, political opinions, religious or philosophical 
          beliefs, or trade union membership, genetic data, biometric data, data concerning health 
          or data concerning a natural person's sex life or sexual orientation. As such, you 
          should not provide any such information as part of your user profile, as part of any 
          correspondence, or by any other means with your use of the Service.
        </p>

        <h4>How do we use personal information?</h4>

        <p>
          We collect personal information when you create an account with the Services, when you 
          update your account information, or otherwise correspond with us. We use your personal 
          information for the following purposes:
        </p>

        <ul className="disc-list">
          <li>
            <p>
              To facilitate the creation of your account with the Service, to secure and personalize 
              your interaction with the Service, and to provide the Services you have requested.
            </p>
          </li>
          <li>
            <p>
              To send you administrative email notifications, such as security or support and maintenance advisories.
            </p>
          </li>
          <li>
            <p>
              We may also use the personal information you provide to contact you regarding your use of the 
              Service or to solicit feedback.
            </p>
          </li>
          <li>
            <p>
              When you communicate with us using one of the methods described in this Privacy Policy, we may 
              also keep a record of the time and date of any correspondence, and also organize this 
              correspondence in one or more of an electronic filing system, an email system or a customer 
              relationship management system.
            </p>
          </li>
          <li>
            <p>
              We link this personal information to data about the way you use our Service and the pages you visit 
              to help enhance, improve, operate, and maintain our Service, our platforms, websites, and other systems.
            </p>
          </li>
          <li>
            <p>
              To prevent fraudulent use of our Service and other systems.
            </p>
          </li>
          <li>
            <p>
              To prevent or take action against activities that are, or may be, in violation of our Terms and 
              Conditions or applicable law.
            </p>
          </li>
          <li>
            <p>
              We may also use the personal information you provide for direct marketing of our Services to you. 
              We allow you to opt-out from receiving marketing communications from us as described in the 
              “Communication choices” section below, and also at the time you sign up and create an account with 
              the Service. Even if you opt-out, we may continue to send you administrative emails, including, for 
              example, periodic updates to this Privacy Policy.
            </p>
          </li>
          <li>
            <p>
              To display personalized or targeted content to you, and to display targeted advertising on third 
              party websites.
            </p>
          </li>
          <li>
            <p>
              For internal product development purposes to develop new products and services, and to improve 
              existing ones.
            </p>
          </li>
          <li>
            <p>
              To respond to your inquiries related to employment opportunities with us, or other general 
              inquiries.
            </p>
          </li>
        </ul>

        <h4>Legal basis for processing in the EU and the United Kingdom</h4>

        <p>
          If you are resident in the EU or the United Kingdom, we need to inform you about the legal basis 
          on which we collect and use your personal information. In the EU and the United Kingdom, the 
          purposes for which we process your personal information are:
        </p>

        <ul className="disc-list">
          <li>
            <p>
              Where we need to perform the contract we are about to enter into or have entered into with 
              you for the Service.
            </p>
          </li>
          <li>
            <p>
              For the purposes of legitimate interests (or those of a third party) and your interests and 
              fundamental rights do not override those interests.
            </p>
          </li>
          <li>
            <p>
              Where we need to comply with a legal or regulatory obligation in the EU and the United Kingdom.
            </p>
          </li>
        </ul>

        <p>
          The legal basis depends on the category of personal information being processed, and the purpose 
          for that processing. The following table indicates each category of personal information we 
          process, and the legal bases we rely on to do so. Where legitimate interest has been used as 
          the legal basis for processing, the specific legitimate interest we use has been described.
        </p>

        <p>
          Please contact us at <a href="mailto:helpdesk@ecomtrack.io">helpdesk@ecomtrack.io </a> if you need details about the specific 
          legal basis we are relying on to process your personal information where one or more legal 
          bases has been indicated.
        </p>
        
        <h4>When do we share personal information?</h4>

        <p>
          Except as described in this Privacy Policy, we will not disclose your personal information 
          that we collect on the Service to third parties without your consent. We may disclose 
          information to third parties if you consent to us doing so, as well as in the following 
          circumstances:
        </p>

        <ul className="disc-list">
          <li>
            <p>
              <span>Service Providers:</span> We may disclose personal information to third-party 
              service providers (e.g., web hosting providers and other SaaS providers) that assist 
              us in our work. We limit the personal information provided to these service providers 
              to that which is reasonably necessary for them to perform their functions, and we 
              require them to agree to maintain the confidentiality of such personal information.
            </p>
          </li>
          <li>
            <p>
              <span>Business Transfers:</span> Information about our users, including personal 
              information, may be disclosed and otherwise transferred to an acquirer, successor 
              or assignee as part of any merger, acquisition, debt financing, sale of company 
              assets, or similar transaction, as well as in the event of an insolvency, 
              bankruptcy, or receivership in which personal information is transferred to one 
              or more third parties as one of our business assets.
            </p>
          </li>
          <li>
            <p>
              <span>To Protect our Interests:</span> We also disclose personal information if 
              we believe that doing so is legally required or is in our interest to protect our 
              property or other legal rights (including, but not limited to, enforcement of our 
              agreements), or the rights or property of others, or otherwise to help protect the 
              safety or security of our Service and other users of the Service.
            </p>
          </li>
          <li>
            <p>
              <span>To Comply with the Law:</span> We may also disclose personal information in 
              response to lawful requests by public authorities, including to meet national 
              security or law enforcement requirements.
            </p>
          </li>
        </ul>

        <h5>Links to external sites</h5>

        <p>
          The Service may contain links to other websites, products, or services that we do not 
          own or operate. The Service also may contain links to Third-Party Sites such as social 
          networking services. If you choose to visit or use any Third-Party Sites or products or 
          services available on or through such Third-Party Sites, please be aware that this 
          Policy will not apply to your activities or any information you disclose while using 
          those Third-Party Sites or any products or services available on or through such 
          Third-Party Sites. We are not responsible for the privacy practices of these Third-Party 
          Sites or any products or services on or through them. Additionally, please be aware that 
          the Service may contain links to websites and services that we operate but that are 
          governed by different privacy policies. We encourage you to carefully review the privacy 
          policies applicable to any website or service you visit other than the Service before 
          providing any personal information on them.
        </p>

        <h4>How long do we keep your personal information for?</h4>

        <p>
          Unless otherwise specifically stated elsewhere in this Privacy Policy, we will retain 
          your personal information for the period necessary to fulfill the purposes outlined in 
          this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>

        <p>
          Aggregated and anonymized data that no longer identifies the user of the Service is 
          maintained for the purposes necessary to provide the Service.
        </p>

        <h4>Communication Choices</h4>

        <p>
          If you receive marketing emails from us, you may unsubscribe at any time by following 
          the instructions contained within the email. You may also opt-out from receiving 
          marketing emails from us, and any other promotional communications that we may send 
          to you from time to time (e.g., by postal mail) by sending your request to us by email 
          at helpdesk@ecomtrack.io or by writing to us at the address given in the ‘Contacting Us’ 
          section of this Privacy Policy.
        </p>

        <p>
          Please be aware that if you opt-out of receiving marketing email from us, it may take 
          up to ten business days for us to process your opt-out request, and you may receive 
          marketing email from us during that period. Additionally, even after you opt-out from 
          receiving marketing messages from us, you will continue to receive administrative and 
          transactional messages from us regarding your use of the Service. 
        </p>

        <p>
          Through your use of the Services, you may have provided us with a mobile telephone number. 
          We may use this telephone number to send automated text/SMS messages, for example to send 
          you reminders for various events, webinars, and strategy sessions you have signed up for. 
          You may opt-out of receiving these messages by replying with a request to opt-out. You may 
          also opt-out of receiving these messages by sending your request to us by email at 
          helpdesk@ecomtrack.io or by writing to us at the address given in the ‘Contacting Us’ 
          section of this Privacy Policy.
        </p>

        <h4>Rights to access</h4>

        <p>
          If you have a user account and profile on our Service, you have the ability to access and 
          update many categories of personal information that you provide to us by logging in to 
          your account and accessing your account settings. If you wish to access or amend any other 
          personal information we hold about you, you may contact us at helpdesk@ecomtrack.io. If you 
          request that we delete your account with our Service, we will do so within a reasonable 
          period of time, but we may need to retain some of your personal information in order to 
          satisfy our legal obligations, or where we reasonably believe that we have a legitimate 
          reason to do so.
        </p>
        
        <h4>EU privacy rights</h4>

        <p>
          If you are located in the EU, you have the following Data Subject Access Rights with 
          respect to your personal information that we hold:
        </p>

        <p>
          <span>Right of access:</span> You have the right to access the personal information that 
          we hold about you.
        </p>

        <p>
          <span>Right to rectification:</span> You may have the right to require us to correct any 
          inaccurate or incomplete personal information we hold about you.
        </p>

        <p>
          <span>Right to erasure:</span> In certain circumstances, you may have the right to the 
          erasure of your personal information we hold about you (for example where it is no longer 
          necessary in relation to the purposes for which it was collected or processed).
        </p>

        <p>
          <span>Right to restriction:</span> You may have the right to request that we restrict 
          processing of your personal information in certain circumstances (for example where the 
          accuracy of the personal information is contested by you, for a period enabling us to 
          verify the accuracy of that personal information).
        </p>

        <p>
          <span>Right to portability:</span> In some limited circumstances, you may have the right 
          to portability which allows you to move, copy or transfer personal information from one 
          organization to another.
        </p>

        <p>
          <span>Right to object:</span> You have a right to object to us processing your personal 
          information when the processing is based on legitimate interests and also to stop us 
          from sending you direct marketing.
        </p>

        <p>
          <span>Rights in relation to automated decision making and profiling:</span> You have 
          the right not to be subject to a decision that affects you based solely on automated 
          processing. We do not perform any automated decision making and profiling.
        </p>

        <p>
          If you wish to exercise one of these rights, please contact us at <a href="mailto:helpdesk@ecomtrack.io">helpdesk@ecomtrack.io </a> 
          or via ‘Contacting us’ section of this Privacy Policy.
        </p>

        <h4>California privacy disclosures</h4>

        <p>
          California law requires us to let you know how we respond to web browser Do Not Track (DNT) 
          signals. Because there currently isn’t an industry or legal standard for recognizing or 
          honoring DNT signals, we don’t respond to them at this time. We await the result of work by 
          the privacy community and industry to determine when such a response is appropriate and what 
          form it should take.
        </p>

        <p>
          A California resident who has provided personal information to a business with whom he/she 
          has established a business relationship for personal, family, or household purposes 
          (“California Customer”) is entitled to request information about whether the business has 
          disclosed personal information to any third parties for the third parties’ direct marketing 
          purposes, subject to certain exceptions, as defined in California Civil Code Sec. 1798.83. 
          In general, subject to certain exceptions, if the business has made such a disclosure of 
          personal information, upon receipt of a request by a California Customer, the business is 
          required to provide, free of charge, a list of all third parties to whom personal information 
          was disclosed in the preceding calendar year, as well as a list of the categories of personal 
          information that were disclosed. California Customers may request further information about 
          our compliance with this law by sending an email to.
        </p>

        <h4>Other US State privacy disclosures</h4>

        <p>
          Our Data Processing Agreement (Agreement) sets out the additional terms, requirements, and 
          conditions on which Ecomtrack.io will process Personal Data when providing services under the 
          Terms of Service Agreement in accordance with the Customer. This Agreement contains the 
          mandatory clauses applicable to Personal Data subject to the new data protection laws, which 
          apply to the collection and processing of personal information forCalifornia, Connecticut, 
          Colorado, Nevada, Virginia, and Utah as required by US state laws.
        </p>

        <h4>What is our policy on children</h4>

        <p>
          Children’s safety is important to us, and we encourage parents and guardians to take an 
          active interest in the online activities of their children. Our Services are not directed 
          to users under the age of 16, and we do not knowingly collect personal information from 
          children under the age of 16 without obtaining parental consent. If we learn that we have 
          collected personal information from a child under the age of 16 on our Services, we will 
          delete that information as quickly as possible. If you believe that we may have collected 
          any such personal information on our Services, please notify us at helpdesk@ecomtrack.io.
        </p>

        <h4>Where do we store and process your personal information?</h4>

        <p>
          <span>International Transfers:</span> Our servers and data centers are located in the 
          United States (US). If you choose to use the Service from outside the US, then you should 
          know that you are transferring your personal information outside of your region and into 
          the U.S. for storage and processing. We may also transfer your data from the U.S. to other 
          countries or regions in connection with storage and processing of data, fulfilling your 
          requests, and operating the Service. You should know that each region can have its own 
          privacy and data security laws, some of which may be less stringent as compared to those 
          of your own region. If you are located in the European Union (EU) or the United Kingdom (UK), 
          then the countries we may transfer your data to, including the US, may not have data protection 
          laws as comprehensive as those in the EU and the UK. To ensure your data is protected, and that 
          we comply with the applicable data protection laws, we have implemented the following measures:
        </p>

        <p>
          <span>Standard Contractual Clauses:</span> We use the Standard Contractual Clauses (SCCs) for 
          transfers of personal information to us, and also for transfer of personal information to 
          third-party service providers. These clauses require the recipients to protect the personal 
          information they receive in accordance with European data protection laws and regulations. 
          Details of our use of SCCs can be provided upon request.
        </p>

        <p>
          <span>Derogations:</span> In certain circumstances we may transfer personal information based 
          on the decorations contained in Article 49 of the General Data Protection Regulation (GDPR).
        </p>

        <p>
          <span>Adequacy Decisions:</span> Where applicable, we may rely on adequacy decisions provided 
          by the European Commission under Article 45 of the GDPR to transfer your personal information 
          outside of the EU or UK.
        </p>

        <p>
          Note that we no longer rely on the EU-US Privacy Shield Framework or the Swiss-US Privacy 
          Shield Framework for the purposes of transferring data outside of the EU and United Kingdom, 
          however we remain certified and committed to the Privacy Shield Framework principles, and this 
          commitment is described in the next section. By providing your personal information to us 
          through your use of the Services, you agree to the various measures we have implemented.
        </p>

        <p>
          <span>Privacy Shield Notice:</span> We comply with the EU-U.S. Privacy Shield Framework 
          and Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce 
          regarding the collection, use, and retention of personal information received from 
          European Union countries, the United Kingdom, and Switzerland (the "Privacy Shield"). 
          We have certified that we adhere to the Privacy Principles of notice, choice, and 
          accountability for onward transfer, security, data integrity, purpose limitation, 
          access, and recourse, enforcement, and liability ("Principles"). If there is any conflict 
          between the policies in this Privacy Policy and the Privacy Shield Principles, the 
          Principles shall govern. In cases of onward transfers of data received pursuant to 
          the EU-U.S. and Swiss-U.S. Privacy Shield, we are potentially liable. To learn more about 
          Privacy Shield, please visit the U.S. Department of Commerce Privacy Shield website: 
          <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>. For more 
          information regarding our Privacy Shield certification, please see: 
          <a href="https://www.privacyshield.gov/list">https://www.privacyshield.gov/list</a>.
        </p>

        <h4>How do we secure your personal information?</h4>

        <p>
          To help protect your data, we use commercially reasonable steps to protect the data that 
          we collect, including your personal information. The reasonable steps include protecting 
          this data against accidental loss, unauthorized use, disclosure, and restricting access to 
          personal information by our staff. The Service is hosted by a third-party hosting company 
          that we have determined maintains adequate security controls and utilizes TLS encryption 
          for all internet communication with the Service. We also require all staff that administer 
          and develop the Service follow industry-standard controls, including strong passwords, the 
          use of anti-virus and anti-malware software, disk encryption and other best practices.
        </p>

        <p>
          We use various 3rd party processors to enable us to provide the Service, and as part of our 
          vendor due-diligence, we review the security controls these processors have in place and 
          ensure they meet industry standards appropriate for the type of data we collect.
        </p>

        <p>
          You should keep in mind, however, that the Service utilizes software, hardware, and 
          networks, which from time to time require maintenance and experience problems beyond our 
          control. Note that no data transmission over the public internet or encryption method can 
          be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of 
          any information that you provide to us. You transmit information to us at your own risk.
        </p>

        <h3>AGREED TERMS</h3>

        <h4>1. Definitions and interpretation</h4>

        <p>The following definitions and rules of interpretation apply in this Agreement.</p>

        <p>1.1 Definitions:</p>

        <p>
          <span>ECOMTRAK.IO Affiliate</span> means an entity that owns or controls, is owned 
          or controlled by or is or under common control or ownership with ECOMTRAK.IO, where 
          control is defined as the possession, directly or indirectly, of the power to direct 
          or cause the direction of the management and policies of an entity, whether through 
          ownership of voting securities, by contract or otherwise.
        </p>

        <p>
          <span>Applicable Data Protection Law</span> means the data protection and privacy law, 
          if it exists on the Effective Date of this Agreement, governing the processing, storage, 
          transfer or other use of Personal Data and privacy of certain individuals including the 
          California Data Privacy Legislation (as applicable to Personal Data of individuals 
          located in California under certain circumstances), the European Data Protection 
          Legislation (as applicable to Personal Data of individuals located in the EU), and the 
          UK GDPR (as applicable to Personal Data of individuals located in the UK), and its 
          associated regulations, if and when in force from time to time.
        </p>

        <p>
          <span>Business Purposes</span> means the services to be provided by ECOMTRAK.IO to the 
          Customer as described in the Terms of Service Agreement and any other purpose 
          specifically identified in Schedule 1.
        </p>

        <p>
          <span>California Data Privacy Legislation</span> means the California Consumer Privacy 
          Act (California Civil Code Sec. 1798.100 et seq., also known as the California Consumer 
          Privacy Act of 2018), the California Privacy Rights Act (California Civil Code Sec. 1798.120 
          et seq., also known as the California Privacy Rights Act of 2020, in effect beginning 
          January 1, 2023), and its associated regulations, if and when in force from time to time.
        </p>

        <p>
          <span>Controller:</span> The UK GDPR defines a controller as: the natural or legal person, 
          public authority, agency or other body which, alone or jointly with others, determines the 
          purposes and means of the processing of personal data.
        </p>

        <p>
          <span>European Data Protection Legislation means:</span> 
        </p>
        <p>
          (i) all applicable data protection 
          and privacy legislation in force from time to time in the UK including without limitation 
          the UK GDPR; the Data Protection Act 2018 (and regulations made thereunder) (DPA 2018); the 
          Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as amended; and
        </p>

        <p>
          (ii) all applicable data protection and privacy legislation in force from time to time in 
          the EU including Regulation 2016/679 of the European Parliament and of the Council on the 
          protection of natural persons with regard to the Processing of Personal Data and on the 
          free movement of such data (General Data Protection Regulation) (EU GDPR); and Directive 
          2002/58/EC concerning the Processing of Personal Data and the protection of privacy in 
          the electronic communications sector;
        </p>

        <p>
          <span>Data Subject:</span> The identified or identifiable living individual to whom the 
          Personal Data relates.
        </p>

        <p>
          <span>EEA</span> means the European Economic Area.
        </p>

        <p>
          <span>Personal Data</span> means any information relating to an identified or identifiable 
          living individual that is processed by ECOMTRACK on behalf of the Customer as a result of, 
          or in connection with, the provision of the services under the Terms of Service Agreement ; 
          an identifiable living individual is one who can be identified, directly or indirectly, in 
          particular by reference to an identifier such as a name, identification number, location 
          data, an online identifier or to one or more factors specific to the physical, physiological, 
          genetic, mental, economic, cultural or social identity of the individual.
        </p>

        <p>
          <span>Processing, processes, processed, process</span> means any activity that involves the 
          use of the Personal Data. It includes, but is not limited to, any operation or set of 
          operations which is performed on the Personal Data or on sets of the Personal Data, whether 
          or not by automated means, such as collection, recording, organisation, structuring, storage, 
          adaptation or alteration, retrieval, consultation, disclosure by transmission, dissemination or 
          otherwise making available, alignment or combination, restriction, erasure or destruction. 
          Processing also includes transferring the Personal Data to third parties.
        </p>

        <p>
          <span>Personal Data Breach</span> means a breach of security leading to the accidental, 
          unauthorised or unlawful destruction, loss, alteration, disclosure of, or access to, the 
          Personal Data.
        </p>

        <p>
          <span>Processor</span> means a natural or legal person, public authority, agency or other 
          body which processes personal data on behalf of the Controller.
        </p>

        <p>
          <span>Records</span> means has the meaning given to it in Clause 12.
        </p>

        <p>
          <span>Standard Contractual Clauses (SCC)</span> means the European Commission's Standard 
          Contractual Clauses for the transfer of Personal Data from the European Union to processors 
          established in third countries (controller-to-processor transfers), as set out in the Annex 
          to Commission Decision 2010/87/EU, a completed copy of which comprises Schedule 2 or such 
          alternative clauses as may be approved by the European Commission from time to time. The SCC 
          shall only apply to the extent that the European Data Protection Legislation governs any 
          Personal Data.
        </p>

        <p>
          <span>Term</span> means this Agreement's term as defined in the policy. 
        </p>

        <p>
          <span>UK GDPR</span> means has the meaning given to it The UK's implementation of the General 
          Data Protection Regulation (GDPR) is called the Data Protection Act 2018. The UK GDPR rules 
          became effective on January 1, 2021. The UK GDPR absorbs the privacy compliance requirements 
          of the EEA's GDPR.
        </p>

        <p>
          The following definitions and rules of interpretation apply in this Agreement.
        </p>

        <p>
          <span>1.2 Applicable Data Protection Law</span> means the data protection and privacy law, 
          if it exists on the Effective Date of this Agreement( the date of acceptance of providing 
          your data on the lead form), governing the processing, storage, transfer or other use of 
          Personal Data and privacy of certain individuals including the California Data Privacy 
          Legislation (as applicable to Personal Data of individuals located in California under 
          certain circumstances), the Colorado, Connecticut, Nevada, Utah, Virgina, European Data 
          Protection Legislation (as applicable to Personal Data of individuals located in the EU), 
          and the UK GDPR (as applicable to Personal Data of individuals located in the UK), and its 
          associated regulations, if and when in force from time to time.
        </p>

        <p>
          <span>1.3 California Data Privacy - https://oag.ca.gov/privacy/ccpa</span> Legislation means 
          the California Consumer Privacy Act (California Civil Code Sec. 1798.100 et seq., also 
          known as the California Consumer Privacy Act of 2018), the California Privacy Rights Act 
          (California Civil Code Sec. 1798.120 et seq., also known as the California Privacy Rights 
          Act of 2020, in effect beginning January 1, 2023), and its associated regulations, if and 
          when in force from time to time. If you are a resident of the State of California and would 
          like to learn how your “personal information” (as defined in the Shine the Light Law, Cal. 
          Civ. Code § 1798.83) is shared with third parties, what categories of personal information 
          that we have shared with third parties in the preceding year, as well as the names and 
          addresses of those third parties, please e-mail us at: helpdesk@ecomtrack.io; call us at: 
          (475) 242-4615; or send us U.S. mail to: Ecomtrak.io Inc 6 Landmark Square, Stamford, CT. 
          Further, if you are a resident of the State of California and would like to opt-out from the 
          disclosure of your personal information to any third-party for marketing purposes, please 
          e-mail us at: helpdesk@ecomtrack.io; call us at: (475) 242-4615; or send us U.S. mail to: 
          Quantum Digital Media, Inc  6 Landmark SQ, Stamford, CT.
        </p>

        <p>
          <span>1.4 YOUR COLORADO PRIVACY RIGHTS - https://leg.colorado.gov/bills/sb21-190 </span> If 
          you are a resident of the State of Colorado and would like to opt-out from the sale of your 
          personal information to any third party data broker, please e-mail us at: 
          helpdesk@ecomtrack.io; call us at: (475) 242-4615; or send us U.S. mail to: Ecomtrak.io 6 
          Landmark Square, Stamford CT 06902.
        </p>

        <p>
          <span>1.5 YOUR CONNECTICUT PRIVACY RIGHTS - 
            https://www.cga.ct.gov/2022/ACT/PA/PDF/2022PA-00015-R00SB-00006-PA.PDF </span> If you are 
            a resident of the State of Connecticut and would like to opt out from the sale of your 
            personal information to any third party data broker, please e-mail us at: 
            helpdesk@ecomtrack.io; call us at: (475) 242-4615; or send us U.S. mail to: Ecomtrak.io 
            6 Landmark Square, Stamford CT 06902.
        </p>

        <p>
          <span>1.6 YOUR NEVADA PRIVACY RIGHTS - https://www.leg.state.nv.us/nrs/nrs-603a.html</span> If 
          you are a resident of the State of Nevada and would like to opt-out from the sale of your 
          personal information to any third party data broker, please e-mail us at: 
          helpdesk@ecomtrack.io; call us at: (475) 242-4615; or send us U.S. mail to: Ecomtrak.io 6 
          Landmark Square, Stamford CT 06902.
        </p>

        <p>
          <span>1.7 YOUR UTAH PRIVACY RIGHTS - https://le.utah.gov/~2022/bills/static/SB0227.html</span> If
          you are a resident of the State of Utah and would like to opt-out from the sale of your 
          personal information to any third party data broker, please e-mail us at: helpdesk@ecomtrack.io; 
          call us at: (475) 242-4615; or send us U.S. mail to: Ecomtrak.io 6 Landmark Square, Stamford CT 
          06902.
        </p>

        <p>
          <span>1.8 YOUR VIRGINA PRIVACY RIGHTS - https://law.lis.virginia.gov/vacode/title59.1/chapter53/</span> If
          you are a resident of the State of Virginia and would like to opt out from the sale of your personal 
          information to any third party data broker, please e-mail us at: helpdesk@ecomtrack.io; call us at: 
          (475) 242-4615; or send us U.S. mail to: Ecomtrak.io 6 Landmark Square, Stamford CT 06902.
        </p>

        <h4>Updates to this Policy</h4>

        <p>
          We may occasionally update this Policy. When we do, we will also revise the ‘last updated’ date at the 
          beginning of the Policy. Your continued use of our Service after such changes will be subject to the 
          then-current policy. If we change this Policy in a manner that is material, we will use reasonable 
          efforts to notify you via the contact methods you have provided of the change prior to applying the 
          change to any personal information that we collected from you prior to the date the change becomes 
          effective. We encourage you to periodically review this Privacy Policy to stay informed about how we 
          collect, use, and disclose personal information.
        </p>      
      </Col>
    </Section>

    <Footer colFlex></Footer>
  </main>
);

export default PrivacyPolicy;
